
:root {
  --bg-dark: #0c0c0c ;
  --bg-light: hwb(0 100% 0%);
  --bg-grey: hsl(210, 44%, 93%);

  --text-dark: #0c0c0c;
  --text-light: #f0f4ff;

  --text-yellow: #E0B225;
  --text-yellow-light: #FFF9E6;

  --text-green: #1D9E95;
  --text-light-green: #89e1dc;

  --text-orange: #E27861;

  --border-light: hsla(0, 0%, 7%, 0.1);
  --border-dark: #010103;

  --button-light: var(--border-light);
  --button-dark: var(--border-dark);

} 


@font-face {
  font-family: 'EuclidCircularA';
  src: url('./assets//fonts/EuclidCircularA-Light.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'EuclidCircularA';
  src: url('./assets/fonts/EuclidCircularA-Regular.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'EuclidCircularA';
  src: url('./assets/fonts/EuclidCircularA-Medium.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'EuclidCircularA';
  src: url('./assets/fonts/EuclidCircularA-Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'EuclidCircularA';
  src: url('./assets/fonts/EuclidCircularA-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}


body {
  min-height: 100vh;
  position: relative;
}

/* HEADER */


.header{
  z-index: 9;
  margin: auto 0;
  background-color: var(--bg-light) !important;
}

.logo{
  width: 75px;
  height: fit-content;
}

.menu-icn{
  z-index: inherit;
  width: 25px ;
}

.menu p{
  color:#0c0c0c;
  transition: color 0.6s ease;

}

.menu p:hover {
  animation: gradientColor 1s ease forwards;
}

@keyframes gradientColor {
  0% {
    color: #0c0c0c;
  }
  50% {
    color: #E0B225;
  }
  75% {
      color: #E27861;
    }
  100% {
    color: #0c0c0c;
  }
}



.menu.active {
  max-height: 100vh; 
}

.menu h3{
  color: #0c0c0c;
}






.menu_services_list {
  animation: textopacity 1s;
}

@keyframes textopacity {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

.menu_services_list.closed {
  animation: textopacity_off 1s;
}

@keyframes textopacity_off {
  from {
      opacity: 1;
  }

  to {
      opacity: 0;
  }
}

/* .large_menu {
  display: block;
}

@media only screen and (max-width: 560px) {
  .large_menu {
      display: none;
  }
} */
 

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
}

.footer-left,
.footer-right {
  display: flex;
}

.footer-right {
  margin-left: auto;
}


.overlay-menu{
top: 50px;
right: 0;
position: absolute;

}


/* CONTENT */



.maxWidth-1920{
  max-width: 1920px !important;
}

  /* slider */



.light-background{
  background-color: var(--bg-light);
}

.grey-background{
  background-color: var(--bg-grey);
}

.dark-background, table, th{
  background-color: #0c0c0c !important;
}

.light-green-bg{
  background-color: var(--text-light-green);
}


.green-background{
  background-color: var(--text-green);
}

.yellow-light-background{
  background-color: var(--text-yellow-light);
}



/* ::-webkit-scrollbar {
  width: 1px; 
} */

/* ::-webkit-scrollbar-track {
  background: transparent; 
} */

/* ::-webkit-scrollbar-thumb {
  background: #0c0c0c; 
}

::-webkit-scrollbar-thumb:hover {
  background: #0c0c0c; 
} */


.h-100{
  min-height: 100vh;
}

.h-90{
  min-height: 90vh;
}

.h-80{
  min-height: 80vh;
}

.h-70{
  min-height: 70vh;
}

.h-60{
  min-height: 60vh;
}

.h-50{
  min-height: 50vh;
}

.h-20{
  min-height: 20vh;
}

.height-100px{
  min-height: 100px;
}

.height-200px{
  min-height: 200px;
}

.height-300px{
  min-height: 300px;
}

.width-100px{
  width: 100px !important;
}

.width-250px{
  width: 250px !important;
}

.width-350px{
  width: 350px !important;
}

.width-400px{
 max-width: 400px !important;
}




.width-50vw{
  width: 50vw;
}

.w-60vw{
  max-width: 60vw !important;
  margin: auto;
}

.w-70vw{
  max-width: 70vw !important;
  margin: auto;
}

.w-100vw{
  max-width: 100vw !important;
}

.overflow-hidden{
  overflow-x: hidden !important;
}

.header-height{
  height: 100px !important;
}

@media screen and (min-width: 768px) {
  .header-height{
    height: 25px;
  }
}


a{
  text-decoration: none !important;
  cursor: pointer !important;
}

p{
  font-family: mundial, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

h1 {
  font-family: mundial, sans-serif;
  font-size: 3.2rem !important;
  line-height: 1.1 !important;
}



h2{
  font-family: mundial, sans-serif;
  font-size: 2.2rem !important;
  line-height: 1.1 !important;
}


h4{
  font-family: mundial, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 24px !important;
  line-height: 1.1;
}


h5{
  font-family: mundial, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 18px !important;
  line-height: 1.1;
}


h6{
  font-family: mundial, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 18px !important;
  line-height: 1.1;
}


@media (max-width: 768px) {
  h1 {
    font-size: 3rem !important;  /* Adjust this for smaller screens */
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 2.2rem !important;  /* Further adjust for very small screens */
  }
}



ul, li{
  list-style: none;
}


.capitalize{
  text-transform: capitalize;
}

.fs-2vw{
  font-size:2vw;
}

.fs-12{
  font-size: 12px;
}

.fs-14{
  font-size: 14px;
}

.fs-16{
  font-size: 16px;
}

.fs-18{
  font-size: 18px;
}

.fs-20{
  font-size: 20px;
}

.fs-22{
  font-size: 22px;
  line-height: 1.4;
}

.fs-24{
  font-size: 24px;
  line-height: 1.4;
}

.fs-28{
  font-size: 28px;
  line-height: 1.4;
}

.fs-30{
  font-size: 30px;
  line-height: 1.2;
}

.fs-32{
  font-size: 32px;
  line-height: 36px;
}

.fs-36{
  font-size: 36px;
}

.fs-40{
  font-size: 40px;
  line-height: 1.1;
}

.fs-42{
  font-size: 42px;
  line-height: 1.1;
}

.fs-3em{
  font-size: 3em !important;
  line-height: 1;
}



.fs-2em{
  font-size: 2em !important;
}

.fs-3em{
  font-size: 3em !important;
}

.fs-4em{
  font-size: 4em !important;
}

.fs-5em{
  font-size: 5em !important;
}

.fw-100{
  font-weight: 100;
}

.fw-200{
  font-weight: 200;
}

.fw-300{
  font-weight: 300;
}

.fw-400{
  font-weight: 400;
}

.fw-500{
  font-weight: 500;
}

.fw-600{
  font-weight: 600;
}

.fw-700{
  font-weight: 700;
}





.text-green{
  color: var(--text-green) !important;
}

.text-yellow{
  color: var(--text-yellow) !important;
}

.text-yellow-light{
  color: var(--text-yellow-light) !important;
}

.light-text a, .light-text{
  color: var(--text-light) !important;
}

.dark-text, .dark-text a{
  color: var(--text-dark) !important;
}


a{
  text-decoration: none;
  color: var(--text-dark);
}

.pointer{
  cursor: pointer !important;

}

.border-dark-around{
  border: 1px solid var(--border-dark);
  border-radius: 10px;
}

.border-top-dark-1px{
  border-top: 1px solid var(--border-dark)
}


.border-bottom-dark{
  border-bottom: 1px solid var(--border-dark)
}

.border-bottom-light-1px{
  border-bottom: 1px solid var(--text-light)
}

.border-bottom-dark-1px{
  border-bottom: 1px solid var(--border-dark)
}

.border-dark-1px{
  border: 1px solid var(--border-dark)

}

.border-dark-2px{
  border: 2px solid var(--border-dark)
}

.border-light-1px{
  border: 1px solid var(--border-light)

}

.rotate-180 {
  transform: rotate(180deg);
}



/* inputs */

.form-control{
  border: none !important;
  border-radius: 0 !important;
  background-color:transparent !important;
}

/* 
works */

.col-md-12 .main-section{
  margin-top: 0px

}

.col-md-12 .main-section .width-50vw{
  width: 50vw;

}

.intro-title{
  font-family: "monarcha", serif !important;
  color: #CFDCEC !important;
}

.full-height-section {
  height: 100vh;
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100vh;
}

.img-container img {
  position: absolute;
  width: auto;
  height: 100vh;
}

.box {
  border: 1px solid rgba(0, 0, 0, 0.035); 
  background: linear-gradient(0deg, #fbfbfb, #f7f7f7); 
  min-height: 220px; 
  padding: 15px;
  border-radius: 10px;
}

.box-dark {
  border: 1px solid rgba(255, 255, 255, 0.1); 
  background: linear-gradient(0deg, #0c0c0c, #1c1c1c); 
  min-height: 220px; 
  padding: 15px;
  border-radius: 10px;
}


.img_box{
  height: auto;

}


@media (max-width: 991px) {
   .col-md-12 .main-section{
       margin-top: 200px
     }
     .col-md-12 .main-section .width-50vw{
        width: 100vw;
     }
 }
 


input[type="range"]::-webkit-slider-thumb {
  background-color: #0c0c0c; 
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ffffff;
}

input[type="range"]::-webkit-slider-thumb {
  background-color: #0c0c0c; 
}

 /* btns */

.dark_form_btn {
  background-color: var(--bg-dark);
  color: var(--bg-light);

}

.gradient_bg{
  background-image:
  radial-gradient(circle at top left, 
    #cbdced 0%,  
    #edf5fd 25%,  
    #cbdced 75%,  
    #85dcb0 100% 
  )
  ;}

  .proposal_btns{
    position: fixed;
    top: 40%;
    
  }

  .selected img, .selected p {
    opacity: 0.35;
  }

  .disabled_btn {
    opacity: 0.35;
    cursor: not-allowed; 
}



/* home */









/* sliders */


.slider {
  /* background-color: #010103; */
  position: relative;
  width: 100%;
  margin: auto;
}

.slider-video {
  width: 100%;
  height: 0;
  padding-top: 56.25%; 
  position: relative;
}

.slider-video video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 1s;

}

.slider-video video.active {
  opacity: 1;
  z-index: 1;
}

.slider-video video.inactive {
  opacity: 0;
  z-index: 0;
}

.slider-button {
  position: absolute;
  background-color: #01010300;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  z-index: 4;
}





.prev {
  left: 0px;
}

.next {
  right: 0px;
}


/* Base Button Style */
.btn-outline-gradient {
  position: relative;
  color: #ffffff;       
  background-color: transparent;
  border: 2px solid transparent;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.3s ease;
  border-radius: 5px;           /* Adjust based on your button shape */
}

/* Linear Color Transition Effect for Border */
.btn-outline-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background: linear-gradient(90deg, white, #ffe6e4, white);
  background-size: 200% 100%; /* Ensure smooth movement */
  z-index: -1;
  animation: border-transition 3s ease-in-out infinite;
  padding: 2px;
}

/* Button Text Style */
.btn-outline-gradient .btn-text {
  position: relative;
  z-index: 1;
}


/* FREE QUOTE */


/* FREE QUOTE */

.cta-button {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: linear-gradient(90deg, #eeeeeb, #d1cfc5, #f6f6f4); /* Gradient colors */
  background-size: 200% 100%; /* Make gradient double the width to allow for the animation */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  transition: background-position 0.4s ease;
  animation: border-transition 3s infinite linear; /* Gradient swipe animation */
}


.cta-button:hover {
  background-position: left; /* Optional: add a subtle change on hover */
}



@keyframes border-transition {
  0% {
    background-position: 100% 0; /* Start with gradient on the right */
  }
  50% {
    background-position: 0% 0;   /* Move the gradient to the left */
  }
  100% {
    background-position: 100% 0; /* Return to the right */
  }
}



/* Base Button Style */
.btn-contact  {
  background-color: #ffffff;  /* Default background color */
  padding: 10px 20px;
  border: 2px solid #333333;  /* Border color */
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;         /* Adjust the border radius */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.btn-contact a {
  color: #333333 !important;

}

/* Hover Effect */
.btn-contact:hover  {
  background-color: #333333;  /* Dark background on hover */
}

.btn-contact:hover a {
  color: #ffffff !important;             /* Change text color to white */
}



/* SLIDER LOGO */
.logo-carousel {
  width: 100%;         /* Adjust width as needed */
  margin: auto;       /* Center the carousel */
  padding: 20px 10px;    /* Optional: Add padding */
}

.logo-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;      /* Optional: Add padding around each logo */
}

.logo-image {
  max-width: 120px;   /* Adjust size as needed */
  max-height: auto;
  object-fit: contain; /* Keep logos undistorted */
}