

.xbox-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #fff;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }
  
  .xbox-button:hover {
    background-color: #666;
  }

  .xbox-button.up {
    transform: rotate(-90deg);
  }
  
  .xbox-button.down {
    transform: rotate(90deg);
  }
  
  .xbox-button.left {
    transform: rotate(180deg);
  }
  
  .xbox-button.right {
    transform: rotate(0deg);
  }
  
  